import {
  useLocation,
  useOrganisationContracts,
  useOrganisationWorkflows,
  usePricingStrategies,
  usePropertyComps,
  useRepairLevels,
  useScheduleOffer,
  useWorkflowSteps,
} from 'fe/queries';
import { useEffect, useState } from 'react';
import { Organisation, Property, WorkflowStep } from 'shared/db';
import { calculateArv, getFormattedEmail } from 'shared/formatter';
import { isNotEmpty, useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

interface UseOfferSettings {
  property?: Property;
  organisation: Organisation;
  onApprove?: () => void;
}

export function useOfferSettings({
  property,
  organisation,
  onApprove,
}: UseOfferSettings) {
  const { mutateAsync: sendOffer, isPending: isSendingOffer } =
    useScheduleOffer();
  const { data: comps, isFetching: isLoadingComps } = usePropertyComps(
    property?.street_address,
    property?.zip_code,
  );
  const { data: pricingStrategies } = usePricingStrategies(organisation.id);
  const { data: repairLevels } = useRepairLevels(organisation.id);
  const { data: workflows } = useOrganisationWorkflows(organisation.id);
  const { data: contracts } = useOrganisationContracts(organisation.id);
  const { data: location } = useLocation(property);
  const [content, setContent] = useState('');

  const form = useForm({
    initialValues: {
      workflow_id: '',
      contract_id: '',
      offerPrice: 0,
      repairBudget: 0,
      email: property?.agent_email || '',
      name: property?.agent_name || '',
    },
    validate: {
      workflow_id: isNotEmpty(),
      email: isNotEmpty(),
      name: isNotEmpty(),
      offerPrice: isNotEmpty(),
    },
  });

  const { data: workflowSteps } = useWorkflowSteps(
    Number(form.values.workflow_id),
  );

  const { content: initialContent, subject } =
    workflowSteps?.[0] && property && location
      ? getFormattedEmail({
          property,
          offerPrice: form.values.offerPrice,
          workflowStep: workflowSteps[0] as unknown as WorkflowStep,
          location: location,
        })
      : { content: '', subject: '' };

  useEffect(() => {
    setContent(initialContent);
  }, [initialContent]);

  const onSubmit = form.onSubmit(async (values) => {
    if (!property) {
      return;
    }
    await sendOffer({
      street_address: property.street_address,
      zip_code: property.zip_code,
      offer_price: values.offerPrice,
      email: values.email,
      name: values.name,
      subject,
      content,
      contract_id: Number(values.contract_id),
      workflow_id: Number(values.workflow_id),
    });
    notifications.show({
      title: `Offer sent to ${values.email} for ${property.street_address}`,
      message: "It'll appear on your inbox in a few minutes.",
    });

    setTimeout(() => {
      onApprove?.();
    }, 3000);
  });

  useEffect(() => {
    form.setFieldValue('workflow_id', String(workflows?.[0]?.id || 0));
  }, [workflows]);

  useEffect(() => {
    if (!contracts?.length) return;
    form.setValues({
      contract_id: String(contracts?.[0].id),
      email: property?.agent_email || '',
      name: property?.agent_name || '',
    });
  }, [contracts, property?.agent_name, property?.agent_email]);

  return {
    arv: comps?.length && property ? calculateArv(property, comps) : 0,
    form,
    workflows,
    contracts,
    pricingStrategies,
    repairLevels,
    onSubmit,
    isSendingOffer,
    isLoading: isLoadingComps,
    initialContent,
    setContent,
  };
}
