import { Divider, Flex, Text, ThemeIcon, Paper, Badge } from '@mantine/core';
import React from 'react';
import { IconSparkles } from '@tabler/icons-react';
import { MessageSuggestion } from './SuggestionTypes/MessageSuggestion';
import { StatusSuggestion } from './SuggestionTypes/StatusSuggestion';
import { useLeadSuggestions } from 'fe/queries';
import { StatusOfCampaignProperty } from 'shared/db';

interface ConversationDetailProps {
  lead_id: number | undefined;
  setEmailContent: (content: string) => void;
}

export function ConvoSuggestions({
  lead_id,
  setEmailContent,
}: ConversationDetailProps) {
  const { data: suggestions, isLoading: isLoadingSuggestions } =
    useLeadSuggestions(lead_id);
  if (!suggestions || suggestions.length === 0) {
    return null;
  }

  return (
    <>
      <Paper p="md" radius="xs" bg="brand.0" mt="sm">
        <Flex align="center" justify="space-between">
          <Text>
            Hello! This is{' '}
            <Text span fw="bold">
              Rafi
            </Text>
            , I have a couple of suggestions:
          </Text>
          <Badge
            color="violet"
            pl={3}
            variant="outline"
            leftSection={
              <ThemeIcon size="xs" variant="transparent">
                <IconSparkles size={10} />
              </ThemeIcon>
            }
          >
            AI
          </Badge>
        </Flex>

        <Divider my="lg" />

        <Flex gap="sm" direction="column">
          {suggestions.map((suggestion) =>
            suggestion.type === 'message' ? (
              <MessageSuggestion
                suggestion_id={suggestion.id}
                key={suggestion.id}
                setEmailContent={setEmailContent}
                data={suggestion.data}
                reasoning={suggestion.reasoning}
              />
            ) : (
              <StatusSuggestion
                key={suggestion.id}
                suggestion_id={suggestion.id}
                lead_id={suggestion.lead_id}
                suggested_status={suggestion.data as StatusOfCampaignProperty}
                reasoning={suggestion.reasoning}
              />
            ),
          )}
        </Flex>
      </Paper>
    </>
  );
}
