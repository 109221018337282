import { trpc } from '../setup';

export const useOrganisationWorkflows = (organisationId: number) =>
  trpc.organization.getWorkflows.useQuery({ organization_id: organisationId });

export const useCreateOrganisationWorkflow = () => {
  const utils = trpc.useUtils();
  return trpc.organization.workflow.create.useMutation({
    onSuccess: (data, input) => {
      utils.organization.getWorkflows.setData(
        { organization_id: input.organization_id },
        (previous) => [...(previous || []), data],
      );
    },
  });
};

export const useUpdateOrganisationWorkflow = () => {
  const utils = trpc.useUtils();
  return trpc.organization.workflow.update.useMutation({
    onSuccess: (data) => {
      utils.organization.getWorkflows.setData(
        { organization_id: data.organisation_id },
        (previous) =>
          previous?.map((prev) => (prev.id === data.id ? data : prev)),
      );
    },
  });
};

export const useDeleteOrganisationWorkflow = () => {
  const utils = trpc.useUtils();
  return trpc.organization.workflow.delete.useMutation({
    onSuccess: (data) => {
      utils.organization.getWorkflows.setData(
        { organization_id: data.organisation_id },
        (previous) => previous?.filter((prev) => prev.id !== data.id),
      );
    },
  });
};
