import { SupabaseClient } from '@supabase/supabase-js';

export enum WorkflowStepType {
  EMAIL = 'Email',
  SMS = 'SMS',
}

export interface WorkflowStep {
  id: number;
  name: string;
  workflow_id: number;
  subject: string | null;
  attachments: string[];
  content: string;
  wait_days: number;
  type: WorkflowStepType;
}

export type NewWorkflowStep = Omit<WorkflowStep, 'id'>;

export async function fetchWorkflowStep(
  supabase: SupabaseClient,
  id: number,
): Promise<WorkflowStep> {
  const { data } = await supabase
    .from('workflow_steps')
    .select()
    .eq('id', id)
    .single();

  return data;
}

export async function fetchWorkflowSteps(
  supabase: SupabaseClient,
  workflow_id: number,
): Promise<WorkflowStep[]> {
  const { data, error } = await supabase
    .from('workflow_steps')
    .select()
    .eq('workflow_id', workflow_id);

  if (error) {
    console.log('error fetching workflow steps: ', error);
    return [];
  }

  return data;
}

interface WorkflowStepByOrderParams {
  workflow_id: number;
  order: number;
}

export async function fetchWorkflowStepByOrder(
  supabase: SupabaseClient,
  { workflow_id, order }: WorkflowStepByOrderParams,
): Promise<WorkflowStep | null> {
  const { data, error } = await supabase
    .from('workflow_steps')
    .select()
    .eq('workflow_id', workflow_id)
    .range(order, order)
    .order('id')
    .single();

  if (error) {
    console.log('error fetching workflow step: ', error);
    return null;
  }

  return data;
}
