import { Badge, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { Campaign } from 'shared/db';
import { IconAlertCircle } from '@tabler/icons-react';
import { SidebarButton } from './SidebarButton';
import { useNavigate } from '@tanstack/react-router';
import { useCampaignFilters } from 'fe/queries';

interface SidebarCampaignButtonProps {
  campaign: Campaign;
  isActive: boolean;
}

export function SidebarCampaignButton({
  campaign,
  isActive,
}: SidebarCampaignButtonProps) {
  const navigate = useNavigate();
  const { data: campaignFilters, isLoading: isLoadingFilters } =
    useCampaignFilters(campaign.id);

  const noFilters = !campaignFilters && !isLoadingFilters;
  const noContractOrWorkflow = !campaign.workflow_id || !campaign.contract_id;
  const displayWarning = !campaign.smart && (noContractOrWorkflow || noFilters);

  return (
    <SidebarButton
      key={campaign.id}
      fillHeight
      onClick={() =>
        navigate({
          to: `/campaigns/$campaignId`,
          params: {
            campaignId: campaign.id,
          },
        })
      }
      active={isActive}
      mt="xs"
      leftSection={
        <>
          <Badge
            size="xs"
            variant="dot"
            color={campaign.active ? 'green' : 'gray'}
          >
            {campaign.type}
          </Badge>

          {displayWarning && (
            <Tooltip label="Campaign configuration is incomplete." withinPortal>
              <ThemeIcon variant="light" color="yellow" radius="xl" size="sm">
                <IconAlertCircle />
              </ThemeIcon>
            </Tooltip>
          )}
        </>
      }
    >
      <Text key={campaign.id} component="a" size="sm">
        {campaign.name}
      </Text>
    </SidebarButton>
  );
}
