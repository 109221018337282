import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Select,
  Tooltip,
  Text,
  Popover,
  Textarea,
  ScrollArea,
  TextInput,
} from '@mantine/core';
import { Organisation, Property } from 'shared/db';
import {
  calculateRepairBudget,
  offerPriceStrategyCalculator,
} from 'shared/formatter';
import { CustomSelector, Drawer, StyledEditor } from 'fe/ui/shared';
import {
  PricingStrategySettings,
  RepairLevelSettings,
  WorkflowManagement,
} from 'fe/feature/organisation-settings';
import { IconInfoCircle, IconPencil } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { useOfferSettings } from './useOfferSettings';
import { useState } from 'react';

interface OfferSettingsProps {
  organisation: Organisation;
  property?: Property;
  skipOffer?: () => void;
  rejectOffer?: (reason: string) => void;
  isRejecting?: boolean;
  onApprove?: () => void;
}

export function OfferSettings({
  property,
  rejectOffer,
  skipOffer,
  onApprove,
  isRejecting = false,
  organisation,
}: OfferSettingsProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const {
    arv,
    form,
    setContent,
    initialContent,
    contracts,
    workflows,
    pricingStrategies,
    repairLevels,
    onSubmit,
    isSendingOffer,
    isLoading,
  } = useOfferSettings({
    property,
    organisation,
    onApprove,
  });

  const [rejectionReason, setRejectionReason] = useState('');
  const [openedRejectPopover, { toggle: toggleReject }] = useDisclosure(false);
  const [openedApprovePopover, { toggle: toggleApprove }] =
    useDisclosure(false);

  return (
    <Box
      w={400}
      bg="#DFF0EE"
      h="100%"
      pt={32}
      px="xl"
      style={{ position: 'relative' }}
    >
      <Drawer key="workflow" opened={opened} onClose={close}>
        <WorkflowManagement />
      </Drawer>

      <form onSubmit={onSubmit} style={{ height: '100%' }}>
        <Flex
          direction="column"
          align="justify-between"
          h="100%"
          justify="space-between"
          pb="xl"
        >
          <Box style={{ position: 'relative', flex: 1 }}>
            <ScrollArea
              style={{ position: 'absolute', height: '100%', width: '100%' }}
              px="sm"
            >
              <Flex
                gap="xs"
                direction="column"
                style={{ opacity: 0.7 }}
                pb="xl"
              >
                <CustomSelector
                  id={property?.zpid}
                  loading={isLoading}
                  step={10000}
                  label="Offer price"
                  placeholder="Price"
                  options={
                    pricingStrategies?.map((strategy) => ({
                      label: strategy.name,
                      value: offerPriceStrategyCalculator({
                        arv: arv || property?.price || 0,
                        repairCost: form.values.repairBudget,
                        strategy,
                      }),
                    })) || []
                  }
                  defaultValue={pricingStrategies?.[0]?.name || ''}
                  popover={<PricingStrategySettings />}
                  {...form.getInputProps('offerPrice')}
                />

                <Flex direction="column" gap={4}>
                  <CustomSelector
                    id={property?.zpid}
                    loading={isLoading}
                    step={1000}
                    label="Rehab budget"
                    placeholder={'Budget'}
                    options={
                      repairLevels?.map((level) => ({
                        label: level.name,
                        value:
                          property && property.living_area_sqft
                            ? calculateRepairBudget(
                                property,
                                level.price_per_sqft,
                              )
                            : 0,
                      })) || []
                    }
                    defaultValue={repairLevels?.[0]?.name || ''}
                    popover={<RepairLevelSettings />}
                    {...form.getInputProps('repairBudget')}
                  />
                </Flex>

                <Flex align="flex-end">
                  <Select
                    size="xs"
                    label="Workflow"
                    placeholder="Pick one"
                    data={
                      workflows?.map((workflow) => ({
                        value: String(workflow.id),
                        label: workflow.name,
                      })) || []
                    }
                    defaultValue={String(workflows?.[0]?.id) || ''}
                    style={{ flex: 1 }}
                    {...form.getInputProps('workflow_id')}
                  />
                  <ActionIcon size="lg" onClick={open}>
                    <IconPencil size="1rem" />
                  </ActionIcon>
                </Flex>

                <div
                  style={{ width: '100%', height: 350, position: 'relative' }}
                >
                  <Box pos="absolute" w="100%" h={350}>
                    <StyledEditor
                      initialContent={initialContent}
                      onUpdate={(newVal) => setContent(newVal)}
                    />
                  </Box>
                </div>

                <Select
                  withinPortal
                  size="xs"
                  label={
                    <Flex align="center">
                      <Text mr={4}>Contract</Text>
                      <Tooltip label="Go to Settings to add/edit contracts">
                        <IconInfoCircle size="1rem" />
                      </Tooltip>
                    </Flex>
                  }
                  placeholder="Pick one"
                  data={
                    contracts?.map((contract) => ({
                      value: String(contract.id),
                      label: contract.label,
                    })) || []
                  }
                  style={{ flex: 1 }}
                  {...form.getInputProps('contract_id')}
                />
              </Flex>
            </ScrollArea>
          </Box>
          <Flex direction="column" gap="xs">
            <Popover
              width={400}
              position="bottom"
              withArrow
              shadow="md"
              opened={openedApprovePopover}
              onChange={toggleApprove}
            >
              <Popover.Target>
                <Button
                  variant={openedApprovePopover ? 'outline' : 'filled'}
                  fullWidth
                  onClick={toggleApprove}
                  loading={isSendingOffer}
                >
                  {openedApprovePopover ? 'Cancel' : 'Send offer'}
                </Button>
              </Popover.Target>
              <Popover.Dropdown>
                <Flex direction="column" gap="lg">
                  <TextInput
                    placeholder="Name"
                    label="Name"
                    withAsterisk
                    {...form.getInputProps('name')}
                  />

                  <TextInput
                    placeholder="Email"
                    label="Email address"
                    withAsterisk
                    {...form.getInputProps('email')}
                  />

                  <Button
                    type="submit"
                    onClick={toggleApprove}
                    fullWidth
                    loading={isSendingOffer}
                  >
                    Send
                  </Button>
                </Flex>
              </Popover.Dropdown>
            </Popover>

            <Flex gap="xs">
              {skipOffer && (
                <Button
                  onClick={skipOffer}
                  color="red"
                  variant="outline"
                  fullWidth
                >
                  Skip
                </Button>
              )}

              {rejectOffer && (
                <Popover
                  width={200}
                  position="bottom"
                  withArrow
                  shadow="md"
                  opened={openedRejectPopover}
                  onChange={toggleReject}
                >
                  <Popover.Target>
                    <Button
                      variant={openedRejectPopover ? 'outline' : 'filled'}
                      color="red"
                      fullWidth
                      onClick={toggleReject}
                    >
                      {openedRejectPopover ? 'Cancel' : 'Reject'}
                    </Button>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Flex direction="column" gap="lg">
                      <Textarea
                        value={rejectionReason}
                        onChange={(event) =>
                          setRejectionReason(event.currentTarget.value)
                        }
                        label="Why are you rejecting this offer? (Optional)"
                        placeholder="Your comment"
                        minRows={4}
                        size="sm"
                      />
                      <Button
                        color="red"
                        fullWidth
                        compact
                        loading={isRejecting}
                        onClick={async () => {
                          await rejectOffer(rejectionReason || 'Bad deal');
                          setRejectionReason('');
                          toggleReject();
                        }}
                      >
                        Finish rejection
                      </Button>
                    </Flex>
                  </Popover.Dropdown>
                </Popover>
              )}
            </Flex>
          </Flex>
        </Flex>
      </form>
    </Box>
  );
}
