import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, ScrollArea } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
  useInboxStore,
  useRecipients,
  useSendEmail,
  useUploadAttachment,
} from 'fe/queries';
import { EmailAddressField, StyledEditor, UploadField } from 'fe/ui/shared';
import { useForm, zodResolver } from '@mantine/form';
import { ConvoSuggestions } from './ConvoSuggestions/ConvoSuggestions';
import { ConversationActions } from './ConversationActions';
import { z } from 'zod';
import { AttachmentCard } from './AttachmentCard';

interface ConversationDetailProps {
  toggleReply: () => void;
  displayReply: boolean;
}

type RecipientOption = 'cc' | 'bcc';
type Form = {
  to: string[];
  content: string;
  cc: string[];
  bcc: string[];
};

export function MessageForm({
  toggleReply,
  displayReply,
}: ConversationDetailProps) {
  const { activeItem } = useInboxStore((state) => ({
    activeItem: state.activeInboxItem,
  }));
  const { data: recipients } = useRecipients(activeItem?.status_id);
  const [emailDraft, setEmailDraft] = useState<string>('');
  const { mutateAsync: uploadAttachment, isPending: uploadingAttachments } =
    useUploadAttachment();
  const [files, setFiles] = useState<File[]>([]);
  const [data, setData] = useState<{ value: string; label: string }[]>([]);
  const [enabledOptions, setEnabledOptions] = useState<RecipientOption[]>([]);

  const form = useForm<Form>({
    initialValues: {
      to: [],
      content: '',
      cc: [],
      bcc: [],
    },
    validate: zodResolver(
      z.object({
        to: z.array(z.string().email()).min(1),
        cc: z.array(z.string().email()),
        bcc: z.array(z.string().email()),
        content: z.string().min(1),
      }),
    ),
  });

  useEffect(() => {
    if (!recipients) return;
    const emailAddress = [
      ...recipients.to,
      ...recipients.cc,
      ...recipients.bcc,
    ];
    setData(emailAddress.map((r) => ({ value: r, label: r })) || []);
    form.setValues(recipients);

    const newOptions: RecipientOption[] = [];

    if (recipients.cc.length > 0) newOptions.push('cc');
    if (recipients.bcc.length > 0) newOptions.push('bcc');

    setEnabledOptions(newOptions);
  }, [recipients]);

  const { mutateAsync: sendEmail, isPending } = useSendEmail();

  const isSending = isPending || uploadingAttachments;

  if (!activeItem) return null;

  const onSubmit = form.onSubmit(async (values) => {
    if (!values.content) {
      notifications.show({
        title: 'Email failed to send',
        message: "Can't send an empty email. Please fill your email.",
        color: 'red',
      });
      return;
    }

    const attachmentPaths = await Promise.all(
      files.map((file) =>
        uploadAttachment({
          organisationId: activeItem.organisation_id!,
          leadId: activeItem.status_id,
          file,
          name: file.name,
        }),
      ),
    );

    await sendEmail({
      ...values,
      lead_id: activeItem.status_id,
      attachments: attachmentPaths,
    });

    notifications.show({
      title: 'Email sent',
      message: null,
    });
    toggleReply();
    setFiles([]);
  });

  return (
    <>
      <ConvoSuggestions
        key="content-suggestions"
        lead_id={activeItem.status_id}
        setEmailContent={(val) => {
          toggleReply();
          setEmailDraft(val);
        }}
      />

      {!displayReply ? (
        <Box my="xl" key="email-actions">
          <ConversationActions item={activeItem} toggleReply={toggleReply} />
        </Box>
      ) : (
        <Flex direction="column" align="flex-end" my="lg" h={400}>
          <form onSubmit={onSubmit} style={{ width: '100%', height: 400 }}>
            <StyledEditor
              {...form.getInputProps('content')}
              initialContent={emailDraft}
              toolbar={
                <Flex
                  direction="column"
                  sx={{
                    borderBottom: '1px solid #e0e0e0',
                    padding: '8px',
                    position: 'sticky',
                    top: 0,
                    zIndex: 10,
                  }}
                >
                  <Flex align="center">
                    <EmailAddressField
                      {...form.getInputProps('to')}
                      label="To"
                      data={data}
                      setData={setData}
                    />

                    <Box>
                      {!enabledOptions.includes('cc') && (
                        <Button
                          onClick={() =>
                            setEnabledOptions((prev) => [...prev, 'cc'])
                          }
                          variant="subtle"
                          size="xs"
                          compact
                          color="dark"
                        >
                          Cc
                        </Button>
                      )}

                      {!enabledOptions.includes('bcc') && (
                        <Button
                          onClick={() =>
                            setEnabledOptions((prev) => [...prev, 'bcc'])
                          }
                          variant="subtle"
                          size="xs"
                          compact
                          color="dark"
                        >
                          Bcc
                        </Button>
                      )}
                    </Box>
                  </Flex>

                  <Flex>
                    {enabledOptions.includes('cc') && (
                      <EmailAddressField
                        {...form.getInputProps('cc')}
                        label="Cc"
                        data={data}
                        setData={setData}
                      />
                    )}

                    {enabledOptions.includes('bcc') && (
                      <EmailAddressField
                        {...form.getInputProps('bcc')}
                        label="Bcc"
                        data={data}
                        setData={setData}
                      />
                    )}
                  </Flex>
                </Flex>
              }
              overlay={
                files.length > 0 && (
                  <Box pos="relative" h={60} m="xs">
                    <ScrollArea
                      style={{ position: 'absolute' }}
                      w="100%"
                      h="100%"
                    >
                      <Flex gap="xs">
                        {files.map((file) => (
                          <AttachmentCard
                            key={file.name}
                            mime_type={file.type}
                            filename={file.name}
                            remove={() =>
                              setFiles(files.filter((f) => f !== file))
                            }
                          />
                        ))}
                      </Flex>
                    </ScrollArea>
                  </Box>
                )
              }
              toolbarButtons={<UploadField setFiles={setFiles} />}
              buttons={() => (
                <Flex justify="flex-start" w="100%" gap="xs">
                  <Button type="submit" loading={isSending} mt="md" size="sm">
                    Send Email
                  </Button>

                  <Button
                    onClick={toggleReply}
                    loading={isSending}
                    mt="md"
                    variant="default"
                    size="sm"
                  >
                    Cancel
                  </Button>
                </Flex>
              )}
            />
          </form>
        </Flex>
      )}
    </>
  );
}
