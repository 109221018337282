import React from 'react';
import { Flex, Text, Tooltip } from '@mantine/core';
import classes from './SidebarButton.module.scss';

interface SidebarButtonProps {
  leftSection?: React.ReactNode;
  children: React.ReactNode;
  reverse?: boolean;
  mt?: string;
  active: boolean;
  onClick?: () => void;
  smallMode?: boolean;
  fillHeight?: boolean;
}
export function SidebarButton({
  children,
  leftSection,
  active,
  onClick,
  reverse = false,
  mt = undefined,
  smallMode = false,
  fillHeight = false,
}: SidebarButtonProps) {
  return (
    <Tooltip
      label={children}
      withinPortal
      position="right"
      disabled={!smallMode}
    >
      <Flex
        h={fillHeight ? undefined : 50}
        onClick={onClick}
        px={!smallMode ? 20 : undefined}
        py={mt}
        className={`${classes.SidebarButton} ${active ? classes.active : ''}`}
        align="center"
        justify="center"
        gap="xs"
        style={{ opacity: 0.8 }}
        direction={!smallMode ? (reverse ? 'row-reverse' : 'row') : undefined}
      >
        {!smallMode && (
          <Text fz="xs" style={{ flex: 1 }}>
            {children}
          </Text>
        )}

        {leftSection}
      </Flex>
    </Tooltip>
  );
}
