import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { InboxItem } from 'shared/db';
import { useOrganisationStore } from '../organisation/useOrganisationStore';
import { useInboxStore } from './useInboxStore';

export const removeSuggestionsInboxItem = () => {
  const queryClient = useQueryClient();
  const { organisation } = useOrganisationStore();
  const {
    filterCampaignType,
    filterCampaigns,
    filterLeadStatus,
    filterSearchTerm,
  } = useInboxStore((state) => ({
    filterLeadStatus: state.filterLeadStatus,
    filterCampaigns: state.filterCampaigns,
    filterCampaignType: state.filterCampaignType,
    filterSearchTerm: state.filterSearchTerm,
  }));
  return (lead_id: number) =>
    queryClient.setQueryData<InfiniteData<InboxItem[]>>(
      [
        'inbox',
        organisation!.id,
        filterSearchTerm,
        filterLeadStatus,
        filterCampaigns,
        filterCampaignType,
      ],
      (previous) => {
        return (
          previous && {
            ...previous,
            pages: previous.pages.map((page) =>
              page.map((item) =>
                lead_id === item.status_id
                  ? {
                      ...item,
                      has_suggestions: false,
                    }
                  : item,
              ),
            ),
          }
        );
      },
    );
};
