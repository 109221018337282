import { SupabaseClient } from '@supabase/supabase-js';

export function grabFileExtension(filename: string) {
  const extension = filename.split('.').pop();
  return extension;
}

export async function uploadProfilePicture(
  supabase: SupabaseClient,
  userId: string,
  file: File,
) {
  const extension = grabFileExtension(file.name);
  const fileName = extension ? `${userId}/portrait.${extension}` : file.name;
  const { data, error } = await supabase.storage
    .from('profile_pictures')
    .upload(fileName, file, {
      upsert: true,
    });
  if (error) {
    console.error('error', error);
    return undefined;
  }
  return data.path;
}

export async function getProfilePicture(
  supabase: SupabaseClient,
  name: string,
) {
  const { data, error } = await supabase.storage
    .from('profile_pictures')
    .createSignedUrl(name, 60);
  if (error || data.signedUrl === null) {
    return undefined;
  }
  return data.signedUrl;
}

export async function mapPicturesToUrls<T extends { picture: string | null }>(
  supabase: SupabaseClient,
  items: T[],
): Promise<T[]> {
  return Promise.all(
    items.map(async (item) => {
      const picture = item.picture
        ? await getProfilePicture(supabase, item.picture)
        : null;
      return {
        ...item,
        picture: picture || null,
      };
    }),
  );
}
