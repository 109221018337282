import { useEffect } from 'react';
import { Organisation, fetchOrganisations } from 'shared/db';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { supabase } from '../utils';
import {
  useArchiveOrganisation,
  useUpdateOrganisation,
} from './useOrganisation';
import { notifications } from '@mantine/notifications';

interface GlobalState {
  organisations: Organisation[];
  organisation: Organisation | null;
  setOrganisation: (organisation: Organisation) => void;
  hasLoaded: boolean;
  smallMode: boolean;
  toggleSmallMode: () => void;
  setSmallMode: (value: boolean) => void;
}

export const useGlobalStore = create<GlobalState>()(
  persist(
    (set) => ({
      organisations: [],
      organisation: null,
      setOrganisation: (organisation: Organisation) => set({ organisation }),
      hasLoaded: false,
      smallMode: false,
      toggleSmallMode: () => set((state) => ({ smallMode: !state.smallMode })),
      setSmallMode: (value: boolean) => set(() => ({ smallMode: value })),
    }),
    {
      name: 'organisation-storage',
      partialize: (state) => ({
        organisations: state.organisations,
        organisation: state.organisation,
        smallMode: state.smallMode,
      }),
    },
  ),
);

export const useOrganisationStore = () => {
  const { organisation, isLoading, hasLoaded } = useGlobalStore((state) => ({
    organisation: state.organisation,
    isLoading: state.organisation === null,
    hasLoaded: state.hasLoaded,
  }));
  const { mutateAsync: archiveOrg, isPending: isArchiving } =
    useArchiveOrganisation();

  const { mutateAsync: update, isPending: isUpdating } =
    useUpdateOrganisation();
  useEffect(() => {
    if (hasLoaded) {
      return;
    }
    useGlobalStore.setState({
      hasLoaded: true,
    });

    fetchOrganisations(supabase).then((organisations) => {
      useGlobalStore.setState({
        organisations: organisations || [],
        organisation: organisation
          ? organisations?.find((org) => org.id === organisation.id)
          : organisations?.[0],
      });
    });
  }, []);

  const archive = async () => {
    if (!organisation) {
      return;
    }
    notifications.show({
      id: 'archive-organisation',
      title: 'Archiving...',
      message: `Archiving organisation ${organisation.name}`,
      color: 'blue',
    });

    await archiveOrg(organisation);

    notifications.update({
      id: 'archive-organisation',
      title: 'Organisation archived',
      message: `Organisation ${organisation.name} has been archived`,
    });

    useGlobalStore.setState({
      organisations: useGlobalStore
        .getState()
        .organisations.filter((org) => org.id !== organisation.id),
    });
    useGlobalStore.setState({
      organisation: useGlobalStore.getState().organisations[0] || null,
    });
  };

  return {
    isLoading,
    organisation,
    organisations: useGlobalStore.getState().organisations,
    setOrganisation: (organisation: Organisation) =>
      useGlobalStore.setState({
        organisation,
      }),
    updateOrganisation: async (
      updatedOrganisationValues: Partial<Organisation>,
    ) => {
      const updatedOrganisation = await update({
        ...organisation!,
        ...updatedOrganisationValues,
      });
      useGlobalStore.setState({
        organisation: updatedOrganisation,
      });
    },
    isUpdating,
    archive,
    isArchiving,
  };
};
