import React from 'react';
import { Avatar, Flex, Text } from '@mantine/core';
import { getInitials } from 'shared/formatter';

interface ProfilePictureProps {
  name: string;
  picture: string | null;
  beforeName?: React.ReactNode;
  extraContent?: React.ReactNode;
}

export function ProfilePicture({
  name,
  picture,
  beforeName,
  extraContent = null,
}: ProfilePictureProps) {
  return (
    <Flex align="center" gap="sm">
      <Avatar color="blue" radius="sm" src={picture}>
        {getInitials(name)}
      </Avatar>
      <Flex direction="column" justify="center">
        <Flex gap={6}>
          <Text fz="sm" fw="bold">
            {beforeName} {name}
          </Text>
        </Flex>

        {extraContent}
      </Flex>
    </Flex>
  );
}
