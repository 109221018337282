import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getApi, postApi } from '../utils';
import { trpc } from '../setup';

export interface SendgridDns {
  [Key: string]: {
    data: string;
    host: string;
    type: string;
    valid: boolean;
  };
}
export interface SendgridDomain {
  dns: SendgridDns;
  id: number;
  valid: boolean;
  incomingParseSetup: boolean;
}

export async function startDomainAuthentication(organisationId: number) {
  const response = await postApi(
    '/startDomainAuthentication?organisation_id=' + organisationId,
  );
  return response;
}

export const useStartDomainAuthentication = (organisationId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => startDomainAuthentication(organisationId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['authenticatedDomain', organisationId],
      });
    },
  });
};

export async function enableEmailParsing(organisationId: number) {
  const response = await postApi(
    '/enableEmailParsing?organisation_id=' + organisationId,
  );
  return response;
}

export const useEnableEmailParsing = (organisationId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => enableEmailParsing(organisationId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['authenticatedDomain', organisationId],
      });
    },
  });
};

export async function validateDomainAuthentication(domainId: number) {
  return await postApi('/validateDomainAuthentication?domain_id=' + domainId);
}

export const useValidateDomainAuthentication = (
  domainId: number,
  organisationId: number,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => {
      return validateDomainAuthentication(domainId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['authenticatedDomain', organisationId],
      });
    },
  });
};

export const useAuthenticatedDomain = (organisationId: number) =>
  trpc.sendgrid.fetchSendgridDomain.useQuery({
    organization_id: organisationId,
  });
