import {
  Flex,
  Header as MantineHeader,
  Button,
  Modal,
  Text,
  Avatar,
  Menu,
} from '@mantine/core';
import { CampaignForm } from '../CampaignForm/CampaignForm';
import { useDisclosure } from '@mantine/hooks';
import { useUserProfile } from 'fe/queries';
import { getInitials } from 'shared/formatter';
import { IconChevronDown, IconUser } from '@tabler/icons-react';
import { modals } from '@mantine/modals';
import { ProfileForm } from './../Profile/ProfileForm';

export function Header() {
  const [opened, { open, close }] = useDisclosure(false);
  const { data: profileData } = useUserProfile();

  const profile = profileData?.profile;

  const openEditProfile = () => {
    modals.open({
      title: 'Edit profile',
      size: 'lg',
      children: <ProfileForm onSuccess={modals.closeAll} />,
    });
  };

  return (
    <MantineHeader height={50}>
      <Modal
        opened={opened}
        onClose={close}
        size="md"
        title="Create new campaign"
        centered
      >
        <CampaignForm smallMode />
      </Modal>

      <Flex
        key={'logo'}
        align="center"
        justify="space-between"
        mb="xs"
        w="100%"
        h="100%"
        pr="xs"
        pl="xs"
      >
        <Button variant="outline" onClick={open}>
          Create campaign
        </Button>

        {profile && (
          <Menu shadow="md" width={200} position="bottom-start">
            <Menu.Target>
              <Button
                variant="subtle"
                color="gray"
                style={{ border: 'none' }}
                rightIcon={<IconChevronDown size={14} />}
              >
                <Flex gap="xs" align="center">
                  <Avatar
                    src={profile.picture}
                    color="blue"
                    size="sm"
                    radius="xl"
                  >
                    {getInitials(`${profile.first_name} ${profile.last_name}`)}
                  </Avatar>
                  <Flex direction="column">
                    <Text size="xs">{`${profile.first_name} ${profile.last_name}`}</Text>
                  </Flex>
                </Flex>
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={openEditProfile}
                icon={<IconUser size={14} />}
              >
                Edit profile
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}
      </Flex>
    </MantineHeader>
  );
}
