import { trpc } from '../setup';

export const useRemoveMember = () => {
  const utils = trpc.useUtils();
  return trpc.organization.members.remove.useMutation({
    onSuccess: (_, params) => {
      utils.organization.members.get.setData(
        { organization_id: params.organization_id },
        (previous) =>
          previous?.filter((member) => member.user_id !== params.user_id),
      );
    },
  });
};

export const useInviteToOrganisation = () => {
  return trpc.organization.members.invite.useMutation();
};

export const useOrganisationMembers = (organization_id: number) =>
  trpc.organization.members.get.useQuery({ organization_id });

export const useToggleNotifications = () => {
  const utils = trpc.useUtils();
  return trpc.organization.members.toggleNotifications.useMutation({
    onSuccess: (_, params) => {
      utils.organization.members.get.setData(
        { organization_id: params.organization_id },
        (previous) =>
          previous?.map((member) =>
            member.user_id !== params.user_id
              ? member
              : { ...member, notifications_enabled: params.enabled },
          ),
      );
    },
  });
};
