import { useMutation } from '@tanstack/react-query';
import { supabase } from '../utils';
import { uploadOutboundMessageAttachment } from 'be/storage';

interface UploadAttachmentParams {
  organisationId: number;
  leadId: number;
  file: File;
  name: string;
}

export const useUploadAttachment = () =>
  useMutation({
    mutationFn: (params: UploadAttachmentParams) =>
      uploadOutboundMessageAttachment(supabase, params),
  });
