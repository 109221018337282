import { SupabaseClient } from '@supabase/supabase-js';
import { v4 as uuidv4 } from 'uuid';
import { sanitizeFilename } from 'shared/formatter';

interface UploadMessageAttachmentsParams {
  name: string;
  organisationId: number;
  messageId: number;
  file: File | Buffer;
}

export async function uploadMessageAttachments(
  supabase: SupabaseClient,
  { organisationId, messageId, file, name }: UploadMessageAttachmentsParams,
) {
  const uuid = uuidv4();
  const filename = `${organisationId}/${messageId}/${uuid}/${sanitizeFilename(name)}`;
  const { error } = await supabase.storage
    .from('message_attachments')
    .upload(filename, file, {
      upsert: true,
    });
  if (error) {
    console.log('error', error);
    throw new Error("Couldn't upload message attachment");
  }
  return filename;
}

interface UploadOutboundMessageAttachmentsParams {
  name: string;
  organisationId: number;
  leadId: number;
  file: File | Buffer;
}

export async function uploadOutboundMessageAttachment(
  supabase: SupabaseClient,
  {
    organisationId,
    leadId,
    file,
    name,
  }: UploadOutboundMessageAttachmentsParams,
) {
  const uuid = uuidv4();
  const filename = `${organisationId}/outbound/${leadId}/${uuid}/${sanitizeFilename(name)}`;
  const { error } = await supabase.storage
    .from('message_attachments')
    .upload(filename, file, {
      upsert: true,
    });
  if (error) {
    console.log('error', error);
    throw new Error("Couldn't upload message attachment");
  }
  return filename;
}

export async function downloadMessageAttachment(
  supabase: SupabaseClient,
  filename: string,
) {
  const { data } = await supabase.storage
    .from('message_attachments')
    .download(filename);

  return data;
}
