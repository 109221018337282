import { format, startOfToday, startOfTomorrow } from 'date-fns';
import { SupabaseClient } from '@supabase/supabase-js';

export enum StatusOfCampaignProperty {
  ACTIVE = 'Active',
  HOT = 'Hot',
  COLD = 'Cold',
  CLOSED_CONTRACT = 'Closed Contract',
  UNDER_CONTRACT = 'Under Contract',
  ABANDONED = 'Abandoned',
  PROCESSING = 'Processing',
  FAILED = 'Failed',
  BLACKLISTED = 'Blacklisted',
  MOVED_TO_CRM = 'Moved to CRM',
  WARM = 'Warm',
  FE_HOT = 'FE_HOT',
  FE_PROCESSING = 'FE_PROCESSING',
  NOT_INTERESTED = 'NOT_INTERESTED',
}

export enum LeadStatusLabel {
  ACTIVE = 'Offer Sent / Pending Response',
  HOT = 'Responded',
  FE_HOT = 'Hot',
  FE_PROCESSING = 'Processing',
  MOVED_TO_CRM = 'Moved to CRM',
  WARM = 'Warm / Working',
  COLD = 'Cold',
  NOT_INTERESTED = 'Not interested',
  UNDER_CONTRACT = 'Under Contract',
  CLOSED_CONTRACT = 'Closed Contract',
  ABANDONED = 'Abandoned',
}

type StatusOption = {
  value: StatusOfCampaignProperty;
  label: LeadStatusLabel;
};

export const StatusOfCampaignPropertyLabels: StatusOption[] = [
  {
    label: LeadStatusLabel.ACTIVE,
    value: StatusOfCampaignProperty.ACTIVE,
  },
  {
    label: LeadStatusLabel.HOT,
    value: StatusOfCampaignProperty.HOT,
  },
  {
    label: LeadStatusLabel.FE_HOT,
    value: StatusOfCampaignProperty.FE_HOT,
  },
  {
    label: LeadStatusLabel.FE_PROCESSING,
    value: StatusOfCampaignProperty.FE_PROCESSING,
  },
  {
    label: LeadStatusLabel.MOVED_TO_CRM,
    value: StatusOfCampaignProperty.MOVED_TO_CRM,
  },
  {
    label: LeadStatusLabel.WARM,
    value: StatusOfCampaignProperty.WARM,
  },
  {
    label: LeadStatusLabel.COLD,
    value: StatusOfCampaignProperty.COLD,
  },
  {
    label: LeadStatusLabel.NOT_INTERESTED,
    value: StatusOfCampaignProperty.NOT_INTERESTED,
  },
  {
    label: LeadStatusLabel.UNDER_CONTRACT,
    value: StatusOfCampaignProperty.UNDER_CONTRACT,
  },
  {
    label: LeadStatusLabel.CLOSED_CONTRACT,
    value: StatusOfCampaignProperty.CLOSED_CONTRACT,
  },
  {
    label: LeadStatusLabel.ABANDONED,
    value: StatusOfCampaignProperty.ABANDONED,
  },
];

export interface CampaignPropertyStatus {
  id: number;
  approved: boolean;
  street_address: string;
  zip_code: number;
  status: StatusOfCampaignProperty | null;
  created_at: Date;
  campaign_id: number | null;
  organisation_id: number | null;
  workflow_id: number | null;
  offer_price: number | null;
  email: string | null;
  name: string | null;
  contract_id: number | null;
}

export type NewCampaignPropertyStatus = Omit<
  CampaignPropertyStatus,
  'id' | 'created_at'
>;

export async function createCampaignPropertyStatus(
  supabase: SupabaseClient,
  newOffer: NewCampaignPropertyStatus,
): Promise<CampaignPropertyStatus> {
  const { data, error } = await supabase
    .from('campaign_property_status')
    .insert(newOffer)
    .select()
    .single();

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't create campaign offer");
  }

  return data;
}

export async function updateCampaignPropertyStatus(
  supabase: SupabaseClient,
  lead: Partial<CampaignPropertyStatus>,
): Promise<CampaignPropertyStatus> {
  const { data, error } = await supabase
    .from('campaign_property_status')
    .update(lead)
    .eq('id', lead.id)
    .select()
    .single();

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't update campaign proeprty statys");
  }

  return data;
}

export async function fetchCampaignPropertyStatusByOrg(
  supabase: SupabaseClient,
  organisation_id: string,
  street_address: string,
  zip_code: number,
): Promise<CampaignPropertyStatus | null> {
  const { data, error } = await supabase
    .from('campaign_property_status')
    .select('*, campaigns!inner(*)')
    .eq('campaigns.organisation_id', organisation_id)
    .eq('street_address', street_address)
    .eq('zip_code', zip_code)
    .single();

  if (error) {
    return null;
  }

  delete data.campaigns;

  return data;
}

interface FetchPropertyStatusByOrgParams {
  street_address: string;
  zip_code: number;
  organisation_id: number;
}
export async function fetchPropertyStatusByOrg(
  supabase: SupabaseClient,
  { organisation_id, street_address, zip_code }: FetchPropertyStatusByOrgParams,
): Promise<CampaignPropertyStatus | null> {
  const { data, error } = await supabase
    .from('campaign_property_status')
    .select()
    .eq('organisation_id', organisation_id)
    .eq('street_address', street_address)
    .eq('zip_code', zip_code)
    .single();

  if (error) {
    return null;
  }

  return data;
}

export async function fetchCampaignPropertyStatusById(
  supabase: SupabaseClient,
  id: number,
): Promise<CampaignPropertyStatus | null> {
  const { data, error } = await supabase
    .from('campaign_property_status')
    .select()
    .eq('id', id)
    .single();

  if (error) {
    return null;
  }

  return data;
}

export async function fetchCampaignPropertyStatusByEmail(
  supabase: SupabaseClient,
  email: string,
): Promise<CampaignPropertyStatus[] | null> {
  const { data, error } = await supabase
    .from('campaign_property_status')
    .select('*, properties!inner (*)')
    .eq('properties.agent_email', email);

  if (error) {
    return null;
  }

  return data;
}

export async function fetchCampaignPropertyStatus(
  supabase: SupabaseClient,
  offer: Omit<
    CampaignPropertyStatus,
    'id' | 'created_at' | 'status' | 'approved'
  >,
): Promise<CampaignPropertyStatus | null> {
  const { data, error } = await supabase
    .from('campaign_property_status')
    .select()
    .eq('campaign_id', offer.campaign_id)
    .eq('street_address', offer.street_address)
    .eq('zip_code', offer.zip_code)
    .single();

  if (error) {
    return null;
  }

  return data;
}

export async function fetchCampaignPropertyStatusByAddress(
  supabase: SupabaseClient,
  offer: Omit<
    CampaignPropertyStatus,
    'id' | 'created_at' | 'status' | 'approved'
  >,
): Promise<CampaignPropertyStatus | null> {
  const { data, error } = await supabase
    .from('campaign_property_status')
    .select()
    .eq('street_address', offer.street_address)
    .eq('zip_code', offer.zip_code)
    .single();

  if (error) {
    return null;
  }

  return data;
}

/**
 * @deprecated Please use OrgInboxItem instead
 */
export type InboxItem = {
  street_address: string;
  zip_code: number;
  campaign_id: number | null;
  approved: boolean;
  organisation_id: number | null;
  agent_name: string | null;
  agent_email: string | null;
  agent_phone: string | null;
  mls_id: string | null;
  zpid: string | null;
  status_id: number;
  img_src: string | null;
  latest_message_excerpt: string | null;
  num_unseen: number;
  num_inbound: number;
  num_messages: number;
  has_suggestions: boolean;
  property_type: string;
  bedrooms: number | null;
  bathrooms: number | null;
  price: number;
  campaign_name: string;
  campaign_type: string | null; // Products;
  campaign_price_discount: number;
  latest_property_message_date: Date;
  latest_property_message_id: number;
  offer_price: number | null;
};

export async function fetchCampaignOffersToday(
  supabase: SupabaseClient,
  campaignId: number,
): Promise<CampaignPropertyStatus[]> {
  const formatSupabase = (date: Date) => format(date, 'yyyy-MM-dd');
  const { data, error } = await supabase
    .from('campaign_property_status')
    .select('*')
    .gte('created_at', formatSupabase(startOfToday()))
    .lt('created_at', formatSupabase(startOfTomorrow()))
    .eq('campaign_id', campaignId);

  if (error) {
    throw new Error("Couldn't fetch campaign offers");
  }

  return data;
}

export async function fetchAllBasicActiveProperties(
  supabase: SupabaseClient,
): Promise<CampaignPropertyStatus[]> {
  const { data: leads, error } = await supabase
    .from('campaign_property_status')
    .select()
    .eq('status', StatusOfCampaignProperty.ACTIVE)
    .is('workflow_id', null);

  if (error) {
    console.log(error);
    throw new Error('Error fetching leads');
  }

  return leads;
}

export async function fetchAllSmartActiveProperties(
  supabase: SupabaseClient,
): Promise<CampaignPropertyStatus[]> {
  const { data: leads, error } = await supabase
    .from('campaign_property_status')
    .select()
    .eq('status', StatusOfCampaignProperty.ACTIVE)
    .not('workflow_id', 'is', null);

  if (error) {
    console.log(error);
    throw new Error('Error fetching leads');
  }

  return leads;
}

export async function fetchCampaignPropertyStatusByOrganisationAndEmail(
  supabase: SupabaseClient,
  organisationId: string | number,
  email: string,
): Promise<CampaignPropertyStatus | null> {
  const { data, error } = await supabase
    .from('campaign_property_status')
    .select('*')
    .eq('organisation_id', organisationId)
    .eq('email', email)
    .limit(1)
    .single();

  if (error) {
    console.log('error fetching status by org and email: ', error);
    return null;
  }

  return data;
}

export async function countCampaignOffers(
  supabase: SupabaseClient,
  organisation_id: number,
  campaignId: number | undefined = undefined,
  dateRange: [Date, Date],
): Promise<number> {
  const query = supabase
    .from('campaign_property_status')
    .select('*, campaigns!inner(*)', { count: 'exact' })
    .gte('created_at', new Date(dateRange[0]).toISOString())
    .lt('created_at', new Date(dateRange[1]).toISOString())
    .neq('status', StatusOfCampaignProperty.PROCESSING)
    .neq('status', StatusOfCampaignProperty.FAILED)
    .neq('status', StatusOfCampaignProperty.BLACKLISTED);

  query.eq('campaigns.organisation_id', organisation_id);
  if (campaignId) {
    query.eq('campaign_id', campaignId);
  }

  const { count, error } = await query;

  if (error) {
    throw new Error("Couldn't count campaign offers");
  }

  console.log('Count of campaign property status: ', count);
  return count || 0;
}
