import { skipToken } from '@tanstack/react-query';
import { CampaignFilters, Products } from 'shared/db';
import { useDebouncedValue } from '@mantine/hooks';
import { trpc } from '../setup';

const keys = {
  all: ['availableListings'] as const,
  city: (city: string) => [...keys.all, city],
  campaign: (campaignId: number, filters?: CampaignFilters) => [
    ...keys.all,
    campaignId,
    filters,
  ],
};

export const useAvailableListingsByLocations = (
  addresses: string[],
  type: Products,
) => {
  const is_fsbo = type === Products.FSBO;
  const data = trpc.useQueries((t) =>
    addresses.map((address) =>
      t.organization.campaigns.calculateListings({
        address,
        is_fsbo,
      }),
    ),
  );
  return {
    data: data.reduce((acc, val) => acc + (val.data || 0), 0),
    isLoading: data.some((result) => result.isLoading),
  };
};

interface UseAvailableListingsByCampaignFilters {
  campaign_id: number;
  filters?: CampaignFilters;
}

export const useAvailableListingsByCampaignFilters = ({
  campaign_id,
  filters,
}: UseAvailableListingsByCampaignFilters) => {
  const [debounced] = useDebouncedValue(filters, 1000);
  return trpc.organization.campaigns.calculateCampaignListings.useQuery(
    debounced
      ? {
          campaign_id,
          filters: {
            ...debounced,
            keywords: debounced.keywords as any,
          },
        }
      : skipToken,
  );
};
