import { SupabaseClient } from '@supabase/supabase-js';
import { Property } from '../property/properties.types';

export interface CampaignOfferRecommendation {
  campaign_id: number;
  street_address: string;
  zip_code: number;
  score: number; // 0-1 scale
  score_explanation: string;
  negative_feedback: string | null; // 0-1 scale
}

export type CampaignOfferRecommendationWithProperty =
  CampaignOfferRecommendation & {
    property: Property;
  };

export async function saveCampaignOfferRecommendation(
  supabase: SupabaseClient,
  recommendation: CampaignOfferRecommendation,
) {
  const { error } = await supabase
    .from('campaign_offer_recommendations')
    .insert(recommendation);

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't insert campaign offer recomendation");
  }
}

interface FetchParams {
  campaign_id: number;
  street_address: string;
  zip_code: number;
}

export async function fetchCampaignOfferRecommendation(
  supabase: SupabaseClient,
  params: FetchParams,
): Promise<CampaignOfferRecommendation | null> {
  const { data, error } = await supabase
    .from('campaign_offer_recommendations')
    .select()
    .eq('campaign_id', params.campaign_id)
    .eq('street_address', params.street_address)
    .eq('zip_code', params.zip_code)
    .single();

  if (error || !data) {
    return null;
  }

  return data;
}

export async function fetchCampaignOfferRecommendations(
  supabase: SupabaseClient,
  campaignId: number,
): Promise<CampaignOfferRecommendationWithProperty[]> {
  const { data, error } = await supabase
    .from('offer_queue')
    .select('*, property:properties!inner(*)')
    .eq('campaign_id', campaignId)
    .eq('score', 1);

  if (error) {
    console.log('error: ', error);
    return [];
  }

  return data;
}

export async function updateCampaignOfferRecommendation(
  supabase: SupabaseClient,
  recommendation: CampaignOfferRecommendation,
) {
  const { data, error } = await supabase
    .from('campaign_offer_recommendations')
    .update(recommendation)
    .eq('campaign_id', recommendation.campaign_id)
    .eq('street_address', recommendation.street_address)
    .eq('zip_code', recommendation.zip_code)
    .select()
    .single();

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't update campaign recommendation");
  }

  return data;
}
