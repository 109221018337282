import { SupabaseClient } from '@supabase/supabase-js';
import { v4 as uuidv4 } from 'uuid';

export interface OrganisationContract {
  id: number;
  label: string;
  filename: string;
  organisation_id: number;
  buyer_name: string | null;
}

export async function createOrganisationContract(
  supabase: SupabaseClient,
  contract: Omit<OrganisationContract, 'id'>,
): Promise<OrganisationContract> {
  const { data, error } = await supabase
    .from('organisation_contracts')
    .insert(contract)
    .select()
    .single();

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't create contract");
  }

  return data;
}

export async function uploadOrganisationContract(
  supabase: SupabaseClient,
  organisation_id: number,
  file: File,
) {
  const uuid = uuidv4();
  const contractFilename = `private/${organisation_id}/${uuid}/${file.name}`;
  const { error } = await supabase.storage
    .from('contracts')
    .upload(contractFilename, file, {
      upsert: true,
    });

  if (error) {
    console.log('error', error);
    throw new Error("Couldn't upload organisation contract");
  }
  return contractFilename;
}

export async function updateOrganisationContract(
  supabase: SupabaseClient,
  contract: OrganisationContract,
) {
  const { error } = await supabase
    .from('organisation_contracts')
    .update(contract)
    .eq('id', contract.id);

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't update contract");
  }

  return contract;
}

export async function fetchOrganisationContract(
  supabase: SupabaseClient,
  contract_id: number,
): Promise<OrganisationContract> {
  const { data, error } = await supabase
    .from('organisation_contracts')
    .select()
    .eq('id', contract_id)
    .single();

  if (error) {
    console.log('error fetching contract: ', error);
    throw new Error('Error fetching contract');
  }

  return data;
}

export async function deleteOrganisationContract(
  supabase: SupabaseClient,
  contract_id: number,
) {
  const { error } = await supabase
    .from('organisation_contracts')
    .delete()
    .eq('id', contract_id);

  if (error) {
    console.log('error deleting contract: ', error);
  }
}
