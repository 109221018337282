import {
  CampaignListing,
  CampaignOfferRecommendationWithProperty,
  NewCampaignPropertyStatus,
  createCampaignPropertyStatus,
  fetchCampaignPropertyStatusById,
} from 'shared/db';
import { callApi, supabase } from '../utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { fetchLeadContractEndpoint } from 'shared/api';
import { removeSuggestionsInboxItem } from '../inbox/utils';
import { trpc } from '../setup';

export const useCreateCampaignPropertyStatus = (campaignId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (campaign: NewCampaignPropertyStatus) =>
      createCampaignPropertyStatus(supabase, campaign),
    onSuccess: (data) => {
      queryClient.setQueriesData<CampaignOfferRecommendationWithProperty[]>(
        { queryKey: ['recommendations', campaignId] },
        (previous) => [
          ...(previous || []).filter(
            (recommendation) =>
              recommendation.street_address !== data.street_address &&
              recommendation.zip_code !== data.zip_code,
          ),
        ],
      );

      queryClient.setQueriesData<CampaignListing[]>(
        { queryKey: ['listings'] },
        (previous) =>
          (previous || []).filter(
            (offer) =>
              offer.street_address !== data.street_address ||
              offer.zip_code !== data.zip_code,
          ),
      );
    },
  });
};

export const useLeadContract = (lead_id: number | null | undefined) =>
  useQuery({
    queryKey: ['lead_contract_pdf', lead_id],
    queryFn: async () =>
      lead_id &&
      callApi(fetchLeadContractEndpoint, { lead_id }).then(
        (res) => res.data as string,
      ),
  });

export const useLead = (lead_id: number | null | undefined) =>
  useQuery({
    queryKey: ['lead', lead_id],
    queryFn: async () =>
      lead_id ? fetchCampaignPropertyStatusById(supabase, lead_id) : null,
  });

export const useUpdateLead = (lead_id: number) => {
  const queryClient = useQueryClient();
  const removeSuggestions = removeSuggestionsInboxItem();
  const utils = trpc.useUtils();
  return trpc.inbox.updateLeadStatus.useMutation({
    onSuccess: (data, params) => {
      queryClient.setQueriesData<CampaignOfferRecommendationWithProperty[]>(
        { queryKey: ['lead', lead_id] },
        (previous) =>
          previous && {
            ...previous,
            status: params.status,
          },
      );
      utils.inbox.getLeadSuggestions.setData({ lead_id }, (previous) => {
        if (!previous) return previous;
        const newSuggestions = previous.filter(
          (suggestion) => suggestion.type !== 'status_change',
        );
        if (newSuggestions.length === 0) {
          removeSuggestions(lead_id);
        }
        return newSuggestions;
      });
      utils.inbox.getLeadInbox.setData({ lead_id }, (previous) => {
        return previous ? [...previous, data] : [data];
      });
    },
  });
};
