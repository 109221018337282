import { Title, Text, Box, Button, Flex, Loader, Divider } from '@mantine/core';
import { Organisation } from 'shared/db';
import {
  useAuthenticatedDomain,
  useBlacklistDomain,
  useBlacklistEmail,
  useBlacklistedDomains,
  useBlacklistedEmails,
  useDeleteBlacklistedDomain,
  useDeleteBlacklistedEmail,
  useEnableEmailParsing,
} from 'fe/queries';
import { CreateDeleteList } from 'fe/ui/shared';

interface OrganisationEmailVerificationProps {
  organisation: Organisation;
}

export function EmailSettings({
  organisation,
}: OrganisationEmailVerificationProps) {
  const organisationId = organisation.id;
  const { data: domain, isLoading } = useAuthenticatedDomain(organisationId);
  const { data: blacklistedDomains } = useBlacklistedDomains(organisationId);
  const { data: blacklistedEmails } = useBlacklistedEmails(organisationId);
  const { mutateAsync: enableEmailParsing, isPending: isLoadingParsing } =
    useEnableEmailParsing(organisationId);

  const { mutateAsync: blacklistDomain, isPending: isBlacklistingDomain } =
    useBlacklistDomain(organisationId);
  const { mutateAsync: blacklistEmail, isPending: isBlacklistingEmail } =
    useBlacklistEmail(organisationId);
  //
  const {
    mutateAsync: deleteBlacklistedDomain,
    isPending: isDeletingBlacklistedDomain,
  } = useDeleteBlacklistedDomain();
  const {
    mutateAsync: deleteBlacklistedEmail,
    isPending: isDeletingBlacklistedEmail,
  } = useDeleteBlacklistedEmail();

  if (!domain) return null;

  if (isLoading) {
    return (
      <Flex justify="center" align="center" style={{ height: '400px' }}>
        <Loader />
      </Flex>
    );
  }

  return (
    <Flex w="100%" justify="space-between" gap="xl" direction="column">
      <Box>
        <div style={{ flex: '1' }}>
          <Title mt="sm" order={3}>
            Domain has been verified
          </Title>

          <Text my="xl">You can start sending offers now.</Text>
        </div>

        <Divider />

        {!domain.incomingParseSetup ? (
          <div style={{ flex: '1' }}>
            <Text mt="xl">
              Click on the button below to enable HeyRafi to automatically mark
              the leads that have replied.
            </Text>
            <Button
              mt="lg"
              loading={isLoadingParsing}
              onClick={() => enableEmailParsing()}
            >
              Enable email parsing
            </Button>
          </div>
        ) : (
          <div>
            <Text mt="xl" fw="bold">
              Email parsing enabled.
            </Text>
          </div>
        )}
      </Box>
      <Box style={{ flex: 1 }}>
        <Title mt="xl" order={2}>
          Blacklisted emails / domains
        </Title>
        <Divider my="sm" />

        <Flex w="100%" gap="sm">
          <CreateDeleteList
            data={blacklistedEmails?.map((domain) => domain.email) || []}
            onCreate={blacklistEmail}
            isCreating={isBlacklistingEmail}
            onDeleteClick={(email: string) => {
              const emailToDelete = blacklistedEmails!.find(
                (d) => d.email === email,
              );
              if (emailToDelete) {
                deleteBlacklistedEmail(emailToDelete.id);
              }
            }}
            isDeleting={isDeletingBlacklistedEmail}
            label="Email"
            placeholder="john@food.com"
          />

          <CreateDeleteList
            data={blacklistedDomains?.map((domain) => domain.domain) || []}
            onCreate={blacklistDomain}
            isCreating={isBlacklistingDomain}
            onDeleteClick={(domain: string) => {
              const domainToDelete = blacklistedDomains!.find(
                (d) => d.domain === domain,
              );
              if (domainToDelete) {
                deleteBlacklistedDomain(domainToDelete.id);
              }
            }}
            isDeleting={isDeletingBlacklistedDomain}
            label="Domain"
            placeholder="food.com"
          />
        </Flex>
      </Box>
    </Flex>
  );
}
